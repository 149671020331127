// 👇 It's important to not import @valu/react-valu-search directly here but
// only the subpackage `lazy`.  This ensures the main entry bundle won't get
// bloated by RVS.
import { LazyValuSearch, select } from "@findkit/pink-ocelot/lazy";

const loader = new LazyValuSearch({
    // import the file exporting the ValuSearch instance as the default export
    load: () => import("./search-ui"),
});

/**
 * INSTRUCTIONS:
 * This loader.tsx and valu-search.tsx are enough to connect valu search
 * to a button that open search. RVS will open in full screen modal.
 *
 * If you need to open search in partial modal, or customize the search,
 * check the docs in README.
 */

loader.init(() => {
    /**
     * Element selectors which should open RVS
     */
    const topModalButton = select(
        "header .additional-items button[data-target='searchModal']",
        HTMLButtonElement,
        { nullable: true },
    );

    const topMobileSearchButton = select(
        "header .navbar button.mobile-button[data-target='searchModal']",
        HTMLButtonElement,
        { nullable: true },
    );

    const wpBlockSearchFormInput = select(
        "form.wp-block-search .wp-block-search__input",
        HTMLInputElement,
        { nullable: true },
    );

    const wpBlockSearchFormSubmit = select(
        "form.wp-block-search .wp-block-search__button",
        HTMLButtonElement,
        { nullable: true },
    );

    const wpSearchFormInput = select(
        "form.search-form .search-field",
        HTMLInputElement,
        { nullable: true },
    );

    const wpSearchFormSubmit = select(
        "form.search-form .search-submit",
        HTMLInputElement,
        { nullable: true },
    );

    const footerModalLink = select(
        "footer .column a[data-target='searchModal']",
        HTMLAnchorElement,
        { nullable: true },
    );

    const buttonIn404 = select(
        ".content button[data-target='searchModal']",
        HTMLButtonElement,
        { nullable: true },
    );

    /**
     * Event listeners for those elements
     */
    if (topModalButton) {
        topModalButton.addEventListener("click", (e) => {
            loader.activate();
        });
    }

    if (topMobileSearchButton) {
        topMobileSearchButton.addEventListener("click", (e) => {
            loader.activate();
        });
    }

    if (buttonIn404) {
        buttonIn404.addEventListener("click", () => {
            loader.activate();
        });
    }

    if (wpBlockSearchFormInput) {
        wpBlockSearchFormInput.addEventListener("focus", (e) => {
            loader.load();
        });
    }

    if (wpBlockSearchFormSubmit) {
        wpBlockSearchFormSubmit.addEventListener("click", (e) => {
            e.preventDefault();
            loader.activate();
        });
    }

    if (wpSearchFormInput) {
        wpSearchFormInput.addEventListener("focus", (e) => {
            loader.load();
        });
    }

    if (wpSearchFormSubmit) {
        wpSearchFormSubmit.addEventListener("click", (e) => {
            e.preventDefault();
            loader.activate();
        });
    }

    if (footerModalLink) {
        footerModalLink.addEventListener("click", (e) => {
            e.preventDefault();
            loader.activate();
        });
    }

    return (vs) => {
        if (wpBlockSearchFormInput) {
            vs.bindInputAsOpener(wpBlockSearchFormInput as HTMLInputElement);
        }

        if (wpSearchFormInput) {
            vs.bindInputAsOpener(wpSearchFormInput as HTMLInputElement);
        }

        vs.initModal();
    };
});
